import React, { Component } from 'react';
import axios from 'axios';
import { parseString } from 'xml2js';
import { FaShippingFast } from 'react-icons/fa';

export default class Shipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      value: ``,
      response: ``,
      deliveryData: `--`,
      deliveryTime: `--`,
      deliveryError: ``
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getShipping = this.getShipping.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    this.setState({ isExpanded: true });
    this.setState({ response: this.state.value });
    this.getShipping(this.state.value);
    event.preventDefault();
  }

  getShipping = async zipcode => {
    let zipcodeXmlResult = null;
    let zipObjectResult = null;
    await Promise.all([
      (zipcodeXmlResult = await axios.request({
        url: process.env.GATSBY_CEP_CORREIOS_URL,
        // url: "https://cors-anywhere.herokuapp.com/http://ws.correios.com.br/calculador/CalcPrecoPrazo.aspx",
        method: `GET`,
        params: {
          nCdEmpresa: process.env.GATSBY_CEP_NCDEMPRESA,
          sDsSenha: process.env.GATSBY_CEP_SDSSENHA,
          sCepOrigem: process.env.GATSBY_CEP_ORIGEM,
          // sCepOrigem:"13482225",
          sCepDestino: zipcode,
          nVlPeso: `0.5`,
          nCdFormato: `1`,
          nVlComprimento: `20`,
          nVlAltura: `20`,
          nVlLargura: `20`,
          sCdMaoPropria: `n`,
          nVlValorDeclarado: `0`,
          sCdAvisoRecebimento: `n`,
          nCdServico: `04014`,
          nVlDiametro: `0`,
          StrRetorno: `xml`,
          nIndicaCalculo: `3`
        }
      }))
    ]);
    // console.log(`result:`, zipcodeXmlResult);
    parseString(zipcodeXmlResult.data, function(err, json) {
      zipObjectResult = json;
    });
    // console.log("data:", zipObjectResult);
    // this.setState({ deliveryPrice: zipObjectResult.Servicos.cServico[0].Valor[0] })
    this.setState({
      deliveryPrice: (
        parseInt(zipObjectResult.Servicos.cServico[0].Valor[0], 10) * 0.4
      ).toFixed(2)
    });
    this.setState({
      deliveryTime: zipObjectResult.Servicos.cServico[0].PrazoEntrega[0]
    });
    this.setState({
      deliveryError: zipObjectResult.Servicos.cServico[0].MsgErro[0]
    });
  };

  render() {
    return (
      <>
        <div className={`${this.state.isExpanded ? `block` : `hidden`}`}>
          <p>Valor estimado de frete e prazo:</p>
          <p>
            R$ {this.state.deliveryPrice} em {this.state.deliveryTime} dia(s)
          </p>
          <p className="text-xs">{this.state.deliveryError}</p>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="md:flex mt-1">
            <div className="w-full">
              <input
                className="bg-gray-200 appearance-none border-2 w-full lg:w-64 border-gray-200 rounded py-2 px-4 text-gray-500 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                id="zipcode"
                onChange={this.handleChange}
                placeholder="Informe seu cep"
                type="text"
                value={this.state.value}
              />
            </div>
          </div>
          <button
            className="w-full lg:w-64 my-2 p-3 rounded shadow text-white font-bold bg-black hover:bg-amarelo hover:text-black"
            type="submit"
          >
            Estimativa de Frete:
            <FaShippingFast className="inline align-middle ml-1" />
          </button>
        </form>
      </>
    );
  }
}
