import React from 'react';
import Slider from 'react-slick';
import { Link } from "gatsby"
// import { FaSearch } from 'react-icons/fa';
// import { AiOutlineShopping } from "react-icons/ai";
// import { MdClose } from 'react-icons/md';
import CustomArrow from './CustomArrow';
import './style.css';


const ProductCarousel = props => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    className: `custom-carousel`,
    // variableWidth: true,
    // rows: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />
  };
  const items = props.products.edges.map(({ node }, id) => {
    return {
      id,
      name: node.name,
      img: node.images ? node.images[0].src : null,
      price: node.price,
      slug: node.slug,
    };
  });

  return (
    <Slider {...settings}>
      {items.map(item => {
        return (
          <div
            className="p-1 mt-2 bg-white"
            key={item.id}
          >
            <Link to={`/produto/${item.slug}`}>
              <img
                alt={item.name}
                async="on"
                className="w-12 h-12 object-cover rounded shadow"
                loading="lazy"
                src={item.img.replace(`.jpg`, `-100x100.jpg`)}
              />
            </Link>
            {/* <div className="text-center text-xs px-1 text-gray-800 font-bold">
              {(item.price * 0.9).toLocaleString(`pt-BR`, {
                style: `currency`,
                currency: `BRL`
              })}
            </div> */}
            {/* <div className="text-center text-xs px-1">{item.name}</div> */}
          </div>
        );
      })}
    </Slider>
  );
};

export default ProductCarousel;
