import React, { useState } from 'react';
import StoreContext from '../context/StoreContext';
import { graphql } from 'gatsby';

import InputNumber from '../components/InputNumber';
import Magnifier from 'react-magnifier';
import Shipping from '../components/Shipping';

import {
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';

import {
  FacebookIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from 'react-share';

import { FaShippingFast } from 'react-icons/fa';
import { FaShieldAlt } from 'react-icons/fa';
import { AiOutlineShopping } from 'react-icons/ai';
import { FaRegCreditCard, FaQrcode } from 'react-icons/fa';
import ProductList from '../components/ProductList';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductCarousel from '../components/ProductCarousel/ProductCarousel';
import SEO from '../components/Seo';
import { Carousel } from 'react-responsive-carousel';

const ProductTemplate = props => {
  const { pageContext, location, data } = props;
  const { product } = pageContext;
  const { crossSellProducts, upSellProducts } = data;
  const [qtd, setQtd] = useState(1);

  const changeQtd = newQtd => {
    setQtd(newQtd);
  };

  return (
    <StoreContext.Consumer>
      {context => {
        if (!context.state) {
          return <></>;
        }
        return (
          <>
            <SEO
              description={product.short_description}
              image={product.images[0].src}
              pathname={location.href}
              title={product.name}
            />
            <section className="px-2 py-6 md:px-16">
              <div className="flex flex-wrap max-w-6xl px-4 pt-5 md:px-8">
                <div className="mb-4 md:w-7/12 md:pr-8">
                  <div>
                    <Carousel
                      emulateTouch
                      infiniteLoop
                      showArrows={true}
                      showIndicators={false}
                      showStatus={false}
                      useKeyboardArrows
                    >
                      {product.images.map((node, index) => (
                        <div key={index}>
                          <img
                            alt={product.name}
                            key={index}
                            loading="lazy"
                            src={node.src}
                          />
                        </div>
                      ))}
                    </Carousel>

                    {/* <Magnifier
                      className="border shadow-md"
                      mgBorderWidth={1}
                      mgHeight={300}
                      mgShape={`square`}
                      mgWidth={300}
                      src={product.images[0].src}
                    /> */}
                  </div>
                </div>

                <div className="text-sm md:w-5/12">
                  <div className="text-base font-bold text-gray-600 uppercase">
                    {product.name}
                  </div>

                  <div className="text-gray-400">Código: {product.sku}</div>

                  <div>
                    <p
                      className="py-2"
                      dangerouslySetInnerHTML={{
                        __html: product.short_description
                      }}
                    />
                  </div>

                  <div className="break-words">
                    <div>
                      <div className="mr-1 text-xl font-bold text-gray-500">
                        R$&nbsp;
                        <span className="text-3xl font-bold text-orange-500">
                          {(product.price * 0.9).toLocaleString(`pt-BR`, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </span>
                      </div>

                      <div className="flex text-xs">
                        <FaQrcode className="w-5 mr-1 text-base text-primary" />
                        <div>
                          No pix{` `}
                          <span className="text-green-600">(10% off)</span>
                        </div>
                      </div>

                      <div className="flex text-xs">
                        <FaRegCreditCard className="w-5 my-1 mr-1 text-primary" />
                        <div>
                          3x R${` `}
                          {(product.price / 3).toLocaleString(`pt-BR`, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                          {` `}
                          <span className="text-green-600">(sem juros)</span>
                        </div>
                      </div>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: product.description
                        }}
                      />

                      {!!(
                        crossSellProducts &&
                        crossSellProducts.edges &&
                        crossSellProducts.edges.length
                      ) && (
                        <div>
                          <div className="text-base font-bold text-gray-600 uppercase">
                            Outras opções de cores:
                          </div>
                          <div className="w-full md:w-1/2">
                            <div className="px-6 container-carosel">
                              <ProductCarousel products={crossSellProducts} />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="py-2">
                        <div className="flex flex-row items-center text-xl">
                          <InputNumber
                            className="w-32 lg:w-16"
                            onChange={changeQtd}
                          />
                          <button
                            className="px-3 py-1 mx-2 text-gray-100 bg-black rounded shadow hover:bg-amarelo hover:text-black"
                            onClick={() =>
                              context.addProductToCart(product.wordpress_id, {
                                qtd: qtd,
                                price: product.price,
                                img: product.images[0].src,
                                name: product.name
                              })
                            }
                          >
                            <span className="flex align-middle">
                              <div className="pt-1 pr-1">
                                <AiOutlineShopping />
                              </div>
                              <div>Comprar</div>
                            </span>
                          </button>
                        </div>
                      </div>

                      <div className="py-2">
                        {product.categories.map((category, index) => (
                          <span
                            className="inline-block px-3 py-1 mr-2 font-semibold text-gray-600 bg-gray-200 rounded-full"
                            key={index}
                          >
                            <a href={`/categoria/${category.slug}`}>
                              #{category.slug}
                            </a>
                          </span>
                        ))}
                      </div>

                      <div className="table w-full py-2 text-xs">
                        <div className="table-row-group">
                          {product.attributes.map((attribute, index) => (
                            <div className="table-row" key={index}>
                              <div className="table-cell px-2 py-2 border-b">
                                {attribute.name}:
                              </div>
                              <div className="table-cell px-2 py-2 border-b">
                                {attribute.options}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <FacebookShareButton
                          className="m-1"
                          url={location.href}
                        >
                          <FacebookIcon round={true} size={32} />
                        </FacebookShareButton>
                        <PinterestShareButton
                          className="m-1"
                          media={product.images[0].src}
                          url={location.href}
                        >
                          <PinterestIcon round={true} size={32} />
                        </PinterestShareButton>
                        <TwitterShareButton className="m-1" url={location.href}>
                          <TwitterIcon round={true} size={32} />
                        </TwitterShareButton>
                        <TelegramShareButton
                          className="m-1"
                          url={location.href}
                        >
                          <TelegramIcon round={true} size={32} />
                        </TelegramShareButton>
                        <WhatsappShareButton
                          className="m-1"
                          url={location.href}
                        >
                          <WhatsappIcon round={true} size={32} />
                        </WhatsappShareButton>
                      </div>

                      <div className="flex flex-wrap py-4">
                        <div className="pb-2 pr-6">
                          <FaShippingFast className="inline m-1 align-bottom" />
                          Frete Grátis acima de R$ 5.000,00 (Estado de SP)
                        </div>
                        <div className="pb-2">
                          <FaShieldAlt className="inline m-1 align-bottom" />
                          Compra 100% segura
                        </div>
                      </div>

                      {/* <hr />

                      <div className="p-2">
                        <Shipping />
                      </div> */}

                      {/*

                    <div className="py-4 border-gray-500">
                    Calcular prazo de entrega:{' '}
                    <input
                    aria-label="zip-code"
                    className="w-32 px-1 border border-gray-400 rounded-sm"
                    placeholder="00000-000"
                    type="text"
                    />{' '}
                    <button
                    className="px-2 bg-gray-400 border border-gray-600 rounded-sm"
                    type="button"
                    >
                    Ok
                    </button>
                    </div>
                    <hr />
                    */}
                    </div>
                  </div>
                </div>
                {!!(
                  upSellProducts &&
                  upSellProducts.edges &&
                  upSellProducts.edges.length
                ) && (
                  <>
                    <div className="w-full py-12">
                      <h2 className="text-center">
                        Você também pode gostar de....
                      </h2>
                    </div>
                    <ProductList
                      addProductToCart={(id, values) =>
                        context.addProductToCart(id, values)
                      }
                      products={upSellProducts.edges}
                    />
                  </>
                )}
                {product.related_products && (
                  <>
                    <div className="w-full py-12">
                      <h2 className="text-center">Produtos relacionados</h2>
                    </div>
                    <ProductList
                      addProductToCart={(id, values) =>
                        context.addProductToCart(id, values)
                      }
                      products={product.related_products}
                    />
                  </>
                )}
              </div>
            </section>
          </>
        );
      }}
    </StoreContext.Consumer>
  );
};

export default ProductTemplate;

export const pageQuery = graphql`
  query MyQuery($upSellIds: [Int], $crossSellIds: [Int]) {
    upSellProducts: allWcProducts(
      filter: { wordpress_id: { in: $upSellIds } }
    ) {
      edges {
        node {
          wordpress_id
          id
          name
          slug
          price
          description
          regular_price
          categories {
            id
            slug
          }
          images {
            src
          }
        }
      }
    }
    crossSellProducts: allWcProducts(
      filter: { wordpress_id: { in: $crossSellIds } }
    ) {
      edges {
        node {
          wordpress_id
          id
          name
          slug
          price
          images {
            src
          }
        }
      }
    }
  }
`;
