import React from 'react';

const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow`}
      onClick={onClick}
      style={{ 
        ...style, 
        display: `flex`,
        alignItems: `center`,
        background: `#cdcdcd`, 
        height: `80%`,
        }}
    />
  );
};

export default PrevArrow;
